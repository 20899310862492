//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { retrieveButter,
         getDictionary } from '~/utils/butterUtils'

export default {
  async asyncData ({ store }) {
    await retrieveButter(store, 'pages/_page/index.vue asyncData ')
  },
  data () {
    return {
      isLoading: true
    }
  },
  computed: {
    showTestData () {
      return this.$store.state.showTestData
    },
    showCodeFragmentMark () {
      return this.$store.state.showCodeFragmentMark
    },
    lang () {
      return this.$i18n.locale.toLowerCase()
    },
    page () {
      const p0 = this.butterSiteInfo
      if (p0) {
        const p1 = p0.find(item => item.id === this.$route.params.page)
        if (p1) {
          return { title: p1['headline_' + this.lang],
                   body: p1['text_' + this.lang]
          }
        } else {
          return { title: '',
                   body: ''
          }
        }
      } else {
        return { title: '',
                 body: ''
        }
      }
    },
    dict () {
      if (this.butterDictionary) {
        return getDictionary(this.butterDictionary, this.lang, 'd', this.$store.state.showDictionaryKeys)
      } else {
        return { "ERROR": "error"}
      }
    },
    butterDictionary () {
      return this.$store.state.butterDictionary
    },
    butterSiteInfo () {
      return this.$store.state.butterSiteInfo
    },
  },
  mounted () {
    this.isLoading = false
  }
}
